<template>
  <screen_width_cont>
    <div class="myBodyGeneral">
      <div class="myHeader_vertical" ref="myHeader_nuevoDesnatario">
        <div class="cont_300_basico ancho_acotado">
          <div class="header_top">
            <headerCerrarInfoCuenta
              v-if="nivelActual == 1"
              @callCuenta="callCuentaLocal"
              @callInfo="callInfoLocal"
              @cancelar="cancelarLocal"
            />
            <headerBackInfoCuenta
              v-else
              @callCuenta="callCuentaLocal"
              @volver_="volver"
            />
          </div>
          <div class="nuevoDesnatarioTitle title">
            {{ t("app.destinatario_new.tit") }}
          </div>
        </div>
        <div
          class="cont_300_basico ancho_acotado nuevoDesnatarioSubTitle"
          v-if="sub_title[0] != ''"
        >
          <div>
            <span class="nuevoDesnatario_subT"
              >{{ sub_title[0] }}&nbsp;&nbsp;&nbsp;</span
            >
            <span class="nuevoDesnatario_subT_destacado"
              >{{ t(`variables.pais.${sub_title[1]}`) }}&nbsp;</span
            >
          </div>
          <div>
            <span class="nuevoDesnatario_subT"
              >{{ sub_title[2] }}&nbsp;&nbsp;&nbsp;</span
            >
            <span class="nuevoDesnatario_subT_destacado">{{
              t(`variables.monedas.${sub_title[3]}.singular`)
            }}</span>
          </div>
        </div>
        <div class="cont_300_basico ancho_acotado nuevoDesnatarioTitle_sub">
          {{ subTitleText }}
        </div>
      </div>

      <blurComp
        class="blur_sup"
        :style="blur_sup_nuevoDesnatario"
        :scroll_to_="scroll_to_top"
        tipo="sup"
      />

      <div
        class="contenido centrado"
        :style="contenido_nuevoDesnatario"
        v-show="cont_visible"
        ref="contenido_nuevoDesnatario"
        @scroll="onScroll"
      >
        <div class="cont_300_basico ancho_acotado">
          <div class="nuevoDesnatarioBody">
            <div
              v-for="(elemento, index) in listaElementosDomOrdenada"
              :key="index"
              :ref="elemento.variable"
            >
              <div
                :class="index != 0 ? 'nuevoDesnatarioInputCont' : ''"
                v-if="
                  elemento.tipo == 'selectPais' &&
                  elemento.elementos &&
                  elemento.elementos.length > 0
                "
              >
                <selectPaisDinamicComp
                  :elementosDom="elemento"
                  @selectPais="cambioElementoLocal"
                  :indiceElementoDom="index"
                  :mensajeError="
                    t('configuracion.data.textos_errores.campoObligatorio')
                  "
                  :disabled="false"
                  tabindex="0"
                  :error="elemento.tieneError"
                  :primeraCarga="primeraCarga"
                  :config="paisEditable"
                  :forzarPrimerEmit="true"
                  @valida_basico_="valida_basico"
                />
              </div>

              <div
                :class="index != 0 ? 'nuevoDesnatarioInputCont' : ''"
                v-if="
                  elemento.tipo == 'select' && elemento.elementos.length > 0
                "
              >
                <selectBasico
                  :elementosDom="elemento"
                  @llamarCambioElemento="cambioElementoLocal"
                  :indiceElementoDom="index"
                  :mensajeError="
                    t('configuracion.data.textos_errores.campoObligatorio')
                  "
                  :disabled="false"
                  tabindex="0"
                  :error="elemento.tieneError"
                  @valida_basico_="valida_basico"
                  :registrando="true"
                />
              </div>

              <div
                :class="index != 0 ? 'nuevoDesnatarioInputCont' : ''"
                v-if="
                  elemento.tipo == 'selectDir' && elemento.elementos.length > 0
                "
              >
                <div v-for="(controlSelect, i) in elemento.elementos" :key="i">
                  <selectBox3
                    :elementosDom="controlSelect"
                    @llamarPoblarOpcionesDir="poblarOpcionesElementoDirLocal"
                    :indiceElementoDom="index"
                    :mostrarLabel="i % 2 == 0"
                    :mensajeError="
                      t('configuracion.data.textos_errores.campoObligatorio')
                    "
                    :disabled="false"
                    tabindex="0"
                    :error="controlSelect.tieneError"
                  />
                </div>
              </div>

              <div
                :class="index != 0 ? 'nuevoDesnatarioInputCont' : ''"
                v-if="elemento.tipo == 'text' || elemento.tipo == 'number'"
              >
                <inputBasicoComp
                  :typeInput="elemento.tipo"
                  :error="elemento.tieneError"
                  @llamarCambioElementoInput="cambioElementoLocal"
                  :mensajeError="[
                    t('configuracion.data.textos_errores.campoObligatorio'),
                    '',
                  ]"
                  :mensajeOk="{ mostrar: false, texto: '' }"
                  :elementosDom="elemento"
                  :indiceElementoDom="index"
                  @valida_basico_="valida_basico"
                />
              </div>

              <div
                :class="index != 0 ? 'nuevoDesnatarioInputCont' : ''"
                v-if="elemento.tipo == 'tel'"
              >
                <inputBasicoDinamicComp_tel
                  :typeInput="elemento.tipo"
                  :error="elemento.tieneError"
                  @llamarCambioElementoInput="cambioElementoLocal"
                  :mensajeError="[
                    t('configuracion.data.textos_errores.campoObligatorio'),
                    '',
                  ]"
                  :mensajeOk="{ mostrar: false, texto: '' }"
                  :elementosDom="elemento"
                  :indiceElementoDom="index"
                  @valida_basico_="valida_basico"
                />
              </div>

              <div
                :class="index != 0 ? 'nuevoDesnatarioInputCont' : ''"
                v-if="
                  elemento.padre_ok &&
                  (elemento.tipo == 'text_hijo' ||
                    elemento.tipo == 'tel_hijo' ||
                    elemento.tipo == 'number_hijo')
                "
              >
                <inputBasicoComp
                  :typeInput="elemento.tipo"
                  :error="elemento.tieneError"
                  @llamarCambioElementoInput="cambioElementoLocal"
                  :mensajeError="[
                    t('configuracion.data.textos_errores.campoObligatorio'),
                    '',
                  ]"
                  :mensajeOk="{ mostrar: false, texto: '' }"
                  :elementosDom="elemento"
                  :indiceElementoDom="index"
                  @valida_basico_="valida_basico"
                />
              </div>
            </div>
          </div>

          <div class="nuevoDesnatarioBtn">
            <btnAction
              :textBtn="
                nivelActual < niveles
                  ? t('app.comunes.btns.continuar')
                  : t('app.comunes.btns.registrar')
              "
              :disabled="!btnActive"
              @action="continuarRegistro"
              colorBack="rojo"
              colorText="blco"
              :estadoBtn="estadoBtn"
              :msg_completar="t('app.comunes.btns.completar_formulario')"
            />
          </div>
        </div>
      </div>

      <blurComp
        class="blur_inf"
        :style="blur_inf_nuevoDesnatario"
        :scroll_to_="scroll_to_bottom"
        tipo="inf"
      />

      <div class="footer" ref="footer_nuevoDesnatario">
        <div class="">
          <pasosGiro :paso="nivelActual" :totales="niveles" />
        </div>

        <div class="footer_env_seguro">
          <enviosSeguros @callEnvioSeguroModal_="callEnvioSeguroModal" />
        </div>
      </div>
    </div>
  </screen_width_cont>
</template>

<script>
import { modalController, menuController } from "@ionic/vue";
import { useI18n } from "vue-i18n";
import headerCerrarInfoCuenta from "../components/generales/headerCerrarInfoCuenta.vue";
import headerBackInfoCuenta from "../components/generales/headerBackInfoCuenta.vue";
import btnAction from "../components/generales/btnAction.vue";
import { mapActions, mapMutations, mapState } from "vuex";
import selectPaisDinamicComp from "../components/destinatario/selectPaisDinamicComp.vue";
import selectBasico from "../components/destinatario/selectBasicoDinamicComp.vue";
import inputBasicoComp from "../components/destinatario/inputBasicoDinamicComp.vue";
import inputBasicoDinamicComp_tel from "../components/destinatario/inputBasicoDinamicComp_tel.vue";
import envioSeguroHome from "./modales/enviosSegurosHome.vue";
import pasosGiro from "../components/generales/pasosProceso.vue";
import enviosSeguros from "../components/generales/envios_seguros.vue";
import blurComp from "../components/generales/blur.vue";
import {
  blur_sup_scroll,
  blur_inf_scroll,
  contenido_style,
  blur_sup_style,
  blur_inf_style,
} from "../assets/funciones/blur";
import selectBox3 from "../components/remitente/selectBoxDinCompRem.vue";
import screen_width_cont from "../components/menuSp/screen_width_cont.vue";

export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },
  name: "nuevoDestinatarioForm",
  components: {
    headerCerrarInfoCuenta,
    headerBackInfoCuenta,
    btnAction,
    selectPaisDinamicComp,
    selectBasico,
    inputBasicoComp,
    inputBasicoDinamicComp_tel,
    pasosGiro,
    enviosSeguros,
    blurComp,
    screen_width_cont,
    selectBox3,
  },
  data() {
    return {
      niveles: 0,
      nivelActual: 0,
      enVista: true,
      primeraCarga: true,
      btnActive: true,
      header: 0,
      footer: 0,
      cont_visible: false,
      obj_valida_basico: {},
      scroll_to_bottom: true,
      scroll_to_top: true,
      contenido_nuevoDesnatario: {},
      blur_sup_nuevoDesnatario: {},
      blur_inf_nuevoDesnatario: {},
      edit_destinatario: null,
      variable_con_error: "",
      estado_validaciones: false,
    };
  },
  computed: {
    ...mapState([
      "configuracion",
      "listaElementosDom",
      "listaElementosDomTemp",
      "infoNodos",
      "remitente",
      "procesoGeneral",
      "transaccion",
      "menuSpDesde",
      "menuSpDesde",
      "desde",
      "heigthScreen",
      "widthScreen",
      "plataforma",
      "resp_forma_entrega_reg",
      "datos_consultados",
      "nav_stack",
    ]),

    listaElementosDomOrdenada() {
      let aux = [];
      if (this.listaElementosDom.length > 0 && this.enVista) {
        this.configuracion.reglasFormularioDestinatario.forEach(
          async (item) => {
            this.listaElementosDom.forEach((elemento) => {
              if (elemento.variable == item[4]) {
                if (elemento.variable == "region_caja") {
                  let new_elementos = {};
                  elemento.elementos.forEach((opcion) => {
                    if (!new_elementos[opcion.value]) {
                      new_elementos[opcion.value] = {};
                      new_elementos[opcion.value] = opcion;
                    }
                  });
                  let new_elementos_array = Object.entries(new_elementos);
                  elemento.elementos = [];
                  new_elementos_array.forEach((element) => {
                    elemento.elementos.push(element[1]);
                  });

                  aux.push(elemento);
                } else if (elemento.variable == "comuna_caja") {
                  let new_elementos = {};
                  elemento.elementos.forEach((opcion) => {
                    if (!new_elementos[opcion.value]) {
                      new_elementos[opcion.value] = {};
                      new_elementos[opcion.value] = opcion;
                    }
                  });
                  let new_elementos_array = Object.entries(new_elementos);
                  elemento.elementos = [];
                  new_elementos_array.forEach((element) => {
                    elemento.elementos.push(element[1]);
                  });

                  aux.push(elemento);
                } else if (elemento.variable == "banco_caja") {
                  let new_elementos = {};
                  elemento.elementos.forEach((opcion) => {
                    if (!new_elementos[opcion.value]) {
                      new_elementos[opcion.value] = {};
                      new_elementos[opcion.value] = opcion;
                    }
                  });
                  let new_elementos_array = Object.entries(new_elementos);
                  elemento.elementos = [];
                  new_elementos_array.forEach((element) => {
                    elemento.elementos.push(element[1]);
                  });

                  aux.push(elemento);
                } else if (elemento.variable == "tipo_cuenta") {
                  if (this.procesoGeneral == "cotizador") {
                    let new_elementos = {};
                    elemento.elementos.forEach((opcion) => {
                      let tipos_cuenta_habiles = {};
                      for (
                        let index = 0;
                        index <
                        this.configuracion.pais[this.transaccion.pais_destino]
                          .tipo_cuenta.length;
                        index++
                      ) {
                        const cuenta =
                          this.configuracion.pais[this.transaccion.pais_destino]
                            .tipo_cuenta[index];
                        if (cuenta[2] == this.transaccion.moneda_destino_id)
                          tipos_cuenta_habiles[cuenta[0]] = {};
                      }
                      if (tipos_cuenta_habiles[opcion.value]) {
                        new_elementos[opcion.value] = {};
                        new_elementos[opcion.value] = opcion;
                      }
                    });
                    let new_elementos_array = Object.entries(new_elementos);
                    elemento.elementos = [];
                    new_elementos_array.forEach((element) => {
                      elemento.elementos.push(element[1]);
                    });
                    aux.push(elemento);
                  } else {
                    aux.push(elemento);
                  }
                } else if (elemento.variable == "moneda_caja") {
                  if (this.procesoGeneral == "cotizador") {
                    let new_elementos = {};
                    elemento.elementos.forEach((opcion) => {
                      let moneda_caja_habiles = {};
                      for (
                        let index = 0;
                        index <
                        this.configuracion.pais[this.transaccion.pais_destino]
                          .moneda_caja.length;
                        index++
                      ) {
                        const moneda_caja =
                          this.configuracion.pais[this.transaccion.pais_destino]
                            .moneda_caja[index];
                        if (
                          moneda_caja[0] == this.transaccion.moneda_destino_id
                        )
                          moneda_caja_habiles[moneda_caja[0]] = {};
                      }
                      if (moneda_caja_habiles[opcion.value]) {
                        new_elementos[opcion.value] = {};
                        new_elementos[opcion.value] = opcion;
                      }
                    });
                    let new_elementos_array = Object.entries(new_elementos);
                    elemento.elementos = [];
                    new_elementos_array.forEach((element) => {
                      elemento.elementos.push(element[1]);
                    });
                    aux.push(elemento);
                  } else {
                    aux.push(elemento);
                  }
                } else {
                  aux.push(elemento);
                }
              }
            });
          }
        );
      }

      // console.log(aux);
      return aux;
    },
    paisEditable() {
      let aux = { editable: true, pais_val: "" };
      if (this.procesoGeneral && this.enVista) {
        if (this.procesoGeneral == "cotizador") {
          aux = { editable: false, pais_val: this.transaccion.pais_destino };
        }
      }

      return aux;
    },
    sub_title() {
      let aux = ["", "", "", ""];
      if (this.procesoGeneral == "cotizador") {
        aux[0] = this.t("app.destinatario_new.pais");
        aux[1] = this.transaccion.pais_destino.split("_")[0];
        aux[2] = `${this.t("app.destinatario_new.moneda")}`;
        aux[3] = this.transaccion.moneda_destino_id;
      }
      return aux;
    },

    subTitleText() {
      let aux = "";
      if (this.nivelActual == 1) {
        aux = this.t("app.destinatario_new.datos_personales");
      } else {
        aux = this.t("app.destinatario_new.forma_entrega");
      }
      return aux;
    },

    estadoBtn() {
      let aux = "inactive";
      if (this.estado_validaciones) {
        aux = "active";
      }
      return aux;
    },
  },
  methods: {
    ...mapMutations([
      "updateDesde",
      "updateListaElementosDom",
      "updateInfoNodos",
      "updateBloquearNavegacion",
      "updateRemitenteDestinatarios",
      "updateListaElementosDomSpPropiedad",
      "updateListaElementosDomSpElemento",
      "destinatarioDatosUnificados",
      "updateDataNewDestinatarioRegistrado",
      "updateConfiguracion",
      "updateListaElementosDomTemp",
      "updateMenuSpDesde",
      "updateRemitenteDestinatariosFormeEntrega",
      "updateListaElementosDomSpPropiedadDirTodo",
      "setshowSpinner",
      "set_nav_stack",
    ]),
    ...mapActions([
      "dibujarNodosNivel",
      "crearNodoInput",
      "cambioElementoVue",
      "tieneErroresNivelDetalle",
      "beneficiarioRegister",
      "poblarOpcionesElementoDir",
      "traer_bancos_pais_destino_v2",
      "traer_comunas_v2",
      "validarTarjetaBeneficiario",
      "traer_monedas_caja",
      "traer_regiones_caja",
      "traer_comunas_caja",
      "traer_regiones_v2",
      "traer_tipo_doc_v2",
      "traer_bancos_caja",
      "traer_regiones_caja_v2",
    ]),

    async verificar_estado_validaciones() {
      let tieneErroresNivelX = await this.tieneErroresNivelDetalle({
        tipo_persona: "Destinatario",
        id_persona: "0",
        nivel: this.nivelActual,
        edit_destinatario: this.edit_destinatario,
      });

      let aux = !tieneErroresNivelX.resultado;
      this.estado_validaciones = aux;
    },
    async poblarOpcionesElementoDirLocal(data) {
      this.verificar_estado_validaciones();
      let cambioData = await this.poblarOpcionesElementoDir({
        tipo_persona: data.tipo_persona,
        variable: data.variable,
        valor: data.valor,
      });

      if (cambioData) {
        if (this.listaElementosDom[data.indiceElementoDom]) {
          this.updateListaElementosDomSpPropiedadDirTodo({
            index: data.indiceElementoDom,
            valor: cambioData.elementos,
          });
        }
      }
    },

    callCuentaLocal() {
      menuController.open("firstMenu");
    },
    callInfoLocal() {
      this.set_nav_stack({
        desde: ["nuevoDestinatarioForm"],
        menuSp: this.nav_stack.menuSp,
      });

      this.$router.push({
        name: "centroAyuda",
        params: { tema: "0" },
        replace: true,
      });
    },
    cancelarLocal() {
      this.$router.push({ name: this.menuSpDesde.desde, replace: true });
    },
    volver() {
      this.nivelActual -= 1;
      this.obj_valida_basico = {};
      this.updateRemitenteDestinatariosFormeEntrega({
        destinatario_id: "0",
        valor: {},
      });
      this.cargarElementosDom();
    },
    async callEnvioSeguroModal() {
      this.updateBloquearNavegacion(true);
      const modal = await modalController.create({
        component: envioSeguroHome,
      });
      modal.onDidDismiss().then(() => {
        this.updateBloquearNavegacion(false);
      });
      return modal.present();
    },

    async cambioElementoLocal(data) {
      this.verificar_estado_validaciones();

      let nombreVariable = data.elemento.split("-")[3];

      if (nombreVariable == "pais_moneda_destino_id") {
        let dato_existe = false;
        let pais_dest = data.valor.split("_")[0];
        if (this.datos_consultados[pais_dest]) {
          if (this.datos_consultados[pais_dest].region) {
            dato_existe = true;
          }
        }

        if (!dato_existe) {
          this.setshowSpinner(true);
          await this.traer_regiones_v2(pais_dest);
          this.setshowSpinner(false);
        }

        dato_existe = false;
        if (this.datos_consultados[pais_dest]) {
          if (this.datos_consultados[pais_dest].tipo_documento_id) {
            dato_existe = true;
          }
        }

        if (!dato_existe) {
          this.setshowSpinner(true);
          await this.traer_tipo_doc_v2({ pais: pais_dest });
          this.setshowSpinner(false);
        }
      }

      // if (nombreVariable == "region") {
      //   let pais_dest =
      //     this.remitente.destinatarios["0"].pais_moneda_destino_id.split(
      //       "_"
      //     )[0];
      //   let dato_existe = false;
      //   if (this.datos_consultados[pais_dest]) {
      //     if (this.datos_consultados[pais_dest].comuna_id) {
      //       if (this.datos_consultados[pais_dest].comuna_id[`${data.valor}`]) {
      //         dato_existe = true;
      //       }
      //     }
      //   }

      //   if (!dato_existe) {
      //     this.setshowSpinner(true);
      //     await this.traer_comunas_v2({
      //       pais: pais_dest,
      //       region: data.valor,
      //     });
      //     this.setshowSpinner(false);
      //   }
      // }

      if (nombreVariable == "forma_entrega_id") {
        let pais_dest =
          this.remitente.destinatarios["0"].pais_moneda_destino_id.split(
            "_"
          )[0];
        if (data.valor == 1) {
          let dato_existe = false;
          if (this.datos_consultados[pais_dest]) {
            if (this.datos_consultados[pais_dest].banco) {
              dato_existe = true;
            }
          }

          if (!dato_existe) {
            this.setshowSpinner(true);
            await this.traer_bancos_pais_destino_v2({
              pais: pais_dest,
              form_ent: data.valor,
            });
            this.setshowSpinner(false);
          }
        } else if (data.valor == 2) {
          let dato_existe = false;
          if (this.datos_consultados[pais_dest]) {
            if (this.datos_consultados[pais_dest].moneda_caja) {
              dato_existe = true;
            }
          }
          if (!dato_existe) {
            this.setshowSpinner(true);
            await this.traer_monedas_caja(pais_dest);
            this.setshowSpinner(false);
          }
        } else if (data.valor == 6) {
          let dato_existe = false;
          if (this.datos_consultados[pais_dest]) {
            if (this.datos_consultados[pais_dest].bco_wallet_cn) {
              dato_existe = true;
            }
          }

          if (!dato_existe) {
            this.setshowSpinner(true);
            await this.traer_bancos_pais_destino_v2({
              pais: pais_dest,
              form_ent: data.valor,
            });
            this.setshowSpinner(false);
          }
        }
      }

      if (nombreVariable == "banco_caja") {
        let pais_dest =
          this.remitente.destinatarios["0"].pais_moneda_destino_id.split(
            "_"
          )[0];
        let dato_existe = false;
        if (this.datos_consultados[pais_dest]) {
          if (this.datos_consultados[pais_dest].region_caja) {
            if (
              this.datos_consultados[pais_dest].region_caja[`${data.valor}`]
            ) {
              dato_existe = true;
            }
          }
        }
        // console.log(variable, data.valor, dato_existe);
        if (!dato_existe) {
          this.setshowSpinner(true);
          await this.traer_regiones_caja_v2({
            banco: data.valor,
            pais: pais_dest,
          });
          this.setshowSpinner(false);
        }
      }

      // console.log("#######--------", nombreVariable, data.valor, this.configuracion.pais[data.valor].region);
      let nombreDependiente = "";
      if (data.clasetienedependiente.length > 0) {
        nombreDependiente = data.clasetienedependiente.substring(17);
      }

      let dependiente_simple = nombreDependiente.split(",");
      // console.log(data.clasetienedependiente, data.clasetienedependiente.substring(0,17), data.clasetienedependiente.substring(17), dependiente_simple);
      dependiente_simple.forEach(async (dependiente) => {
        let cambioData = await this.cambioElementoVue({
          elemento: data.elemento,
          valor: data.valor,
          accion: data.accion,
          tipo_persona: data.tipo_persona,
          clasetienedependiente: `${data.clasetienedependiente.substring(
            0,
            17
          )}${dependiente}`,
          borrarValorHijo: true,
          edit_destinatario: this.edit_destinatario,
        });
        // console.log(data.elemento, data.valor);
        let elemento = data.elemento.split("-");
        // console.log(".........", nombreVariable, data.valor, cambioData, data);
        if (cambioData.cargarDataAlElementoDom) {
          let index = this.listaElementosDom.findIndex((item) => {
            return (
              `slct-${item.nombre_elemento}` ==
              cambioData.dataNuevaPasada.elemento
            );
          });

          if (index > -1) {
            this.updateListaElementosDomSpPropiedad({
              index: index,
              propiedad: "fireChange",
              valor: false,
            });
            this.updateListaElementosDomSpPropiedad({
              index: index,
              propiedad: "elementos",
              valor: cambioData.elementos.elementos,
            });
            this.updateListaElementosDomSpPropiedad({
              index: index,
              propiedad: "isFilledTag",
              valor: cambioData.isFilledTag,
            });
            this.updateListaElementosDomSpPropiedad({
              index: index,
              propiedad: "tieneError",
              valor: cambioData.tieneError,
            });

            // setTimeout(() => {
            //   this.updateListaElementosDomSpPropiedad({
            //     index: index,
            //     propiedad: "fireChange",
            //     valor: true,
            //   });
            // }, 500);
          }
        } else if (
          cambioData.tipo_elemento &&
          cambioData.tipo_elemento == "input"
        ) {
          let index = this.listaElementosDom.findIndex((item) => {
            return (
              item.identificador == cambioData.dataNuevaPasada.identificador
            );
          });
          // console.log(index);
          if (index > -1) {
            this.updateListaElementosDomSpPropiedad({
              index: index,
              propiedad: "fireChange",
              valor: false,
            }); //this.lista_elementos_dom_local[index].fireChange = false;
            this.updateListaElementosDomSpElemento({
              index: index,
              valor: cambioData.dataNuevaPasada,
            }); //this.lista_elementos_dom_local[index] = cambioData.dataNuevaPasada;
            setTimeout(() => {
              this.updateListaElementosDomSpPropiedad({
                index: index,
                propiedad: "fireChange",
                valor: true,
              }); //this.lista_elementos_dom_local[index].fireChange = true;
            }, 500);
            // console.log(this.listaElementosDom[index]);
          }
        } else {
          // console.log("-------------------", data, cambioData);
          let index = this.listaElementosDom.findIndex((item) => {
            return item.variable == elemento[3];
          });

          if (index > -1 && this.listaElementosDom[index]) {
            this.updateListaElementosDomSpPropiedad({
              index: index,
              propiedad: "tieneError",
              valor: cambioData.tieneError,
            });
          }
        }

        // let elemento = data.elemento.split("-");

        // if (elemento[3] && elemento[3] == "forma_entrega_id") {
        //   this.cargarElementosDom();
        // }
        // else
        if (elemento[3] && elemento[3] == "pais_moneda_destino_id") {
          this.primeraCarga = false;
        }
      });
    },
    cargarElementosDom() {
      this.updateListaElementosDom([]);
      this.updateInfoNodos([]);

      this.dibujarNodosNivel({
        tipo_persona: "Destinatario",
        id_persona: 0,
        nivel: this.nivelActual,
        edit_destinatario: this.edit_destinatario,
      });
    },

    async continuarRegistro() {
      console.log(this.remitente.destinatarios[0]);

      let tieneErroresNivelX = await this.tieneErroresNivelDetalle({
        tipo_persona: "Destinatario",
        id_persona: "0",
        nivel: this.nivelActual,
        edit_destinatario: this.edit_destinatario,
      });

      if (!tieneErroresNivelX.resultado) {
        if (this.nivelActual + 1 <= this.niveles) {
          this.nivelActual += 1;

          this.obj_valida_basico = {};

          this.$refs["contenido_nuevoDesnatario"].scrollTo({
            top: 0,
            behavior: "smooth",
          });

          this.setshowSpinner(false);
          this.cargarElementosDom();
        } else {
          this.btnActive = false;

          this.updateDataNewDestinatarioRegistrado({
            nombre: `${this.remitente.destinatarios[0].nombre1} ${this.remitente.destinatarios[0].apellido1} ${this.remitente.destinatarios[0].apellido2}`,
          });
          await this.beneficiarioRegister({
            id: "0",
            sujeto: "nuevo_destinatario",
          });
        }
      } else {
        this.variable_con_error = "";
        this.listaElementosDom.forEach((element) => {
          element.tieneError = false;
        });
        // console.log(this.configuracion, tieneErroresNivelX);
        tieneErroresNivelX.detalle.forEach((error) => {
          let aux = this.listaElementosDom.findIndex((elem) => {
            return elem.variable == error[1] && error[0] < 2;
          });
          if (aux > -1) {
            if (this.listaElementosDom[aux].tipo == "selectDir") {
              this.listaElementosDom[
                aux
              ].elementos.elementos[0].tieneError = true;
            }
            this.listaElementosDom[aux].tieneError = true;
            if (this.variable_con_error == "") {
              this.scroll_to_error(error[1]);
              this.variable_con_error = error[1];
            }
          }
        });
      }
    },
    scroll_to_error(variable_con_error) {
      this.$refs["contenido_nuevoDesnatario"].scrollTo({
        top: this.$refs[variable_con_error][0].offsetTop,
        behavior: "smooth",
      });
    },
    valida_basico(data) {
      this.obj_valida_basico[data.variable] = data.valida;
      this.verificar_estado_validaciones();
    },
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      this.scroll_to_top = blur_sup_scroll(scrollTop);
      this.scroll_to_bottom = blur_inf_scroll(
        scrollTop,
        clientHeight,
        scrollHeight
      );
    },
  },
  watch: {
    async infoNodos() {
      if (this.infoNodos.length > 0 && this.enVista) {
        for (const element of this.infoNodos) {
          await this.crearNodoInput({
            elementoDom: element.elementoDom,
            tipo_persona: element.tipo_persona,
            id_persona: element.id_persona,
            variable: element.variable,
            pais: element.pais,
            edit_destinatario: this.edit_destinatario,
          });
        }
        this.updateListaElementosDom(this.listaElementosDomTemp);
      }
    },
    heigthScreen() {
      this.contenido_nuevoDesnatario = contenido_style(
        this.heigthScreen,
        this.header,
        this.footer,
        this.plataforma == "web" ? 63 : 0
      );
    },
    resp_forma_entrega_reg() {
      if (this.resp_forma_entrega_reg.status && this.enVista) {
        if (this.resp_forma_entrega_reg.resp) {
          if (this.menuSpDesde.desde == "cotizadorDestinatario") {
            let destinatario = {
              accion: "seleccionar_transaccion",
              id_persona: this.resp_forma_entrega_reg.destinatario_id,
              forma_entrega_id:
                this.remitente.destinatarios["0"].detalle_entrega["0"]
                  .forma_entrega_id,
              tipo_cuenta:
                this.remitente.destinatarios["0"].detalle_entrega["0"]
                  .tipo_cuenta,
              banco:
                this.remitente.destinatarios["0"].detalle_entrega["0"]
                  .id_banco_destino,
              cuenta_corriente:
                this.remitente.destinatarios["0"].detalle_entrega["0"]
                  .numero_cuenta,
              numero_cuenta:
                this.remitente.destinatarios["0"].detalle_entrega["0"]
                  .numero_cuenta,
              nro_cuenta:
                this.remitente.destinatarios["0"].detalle_entrega["0"]
                  .numero_cuenta,
              motivo_transaccion: "",
              detalle_entrega_id: this.resp_forma_entrega_reg.fe_id,
            };
            this.validarTarjetaBeneficiario(destinatario);
          }

          this.setshowSpinner(false);
          this.$router.push({
            name: "destinMyDataForm_finalizar",
            replace: true,
          });
        }
      }
    },
  },
  created() {},

  ionViewWillEnter() {
    this.enVista = true;
// console.log(this.transaccion);
    this.updateDesde("beneficiarioNuevo");

    this.updateListaElementosDom([]);
    this.updateInfoNodos([]);
    this.updateRemitenteDestinatarios({
      pais_moneda_destino_id: null,
    });

    this.niveles = Math.max.apply(
      Math,
      this.configuracion.reglasFormularioDestinatario.map((campo) => {
        return campo[3];
      })
    );
    this.nivelActual = 1;

    this.edit_destinatario = null;

    this.edit_destinatario = {
      editFE: true,
      indexFE: "0",
    };

    this.cargarElementosDom();
  },
  ionViewDidEnter() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.header = this.$refs["myHeader_nuevoDesnatario"].offsetHeight;
        this.footer = this.$refs["footer_nuevoDesnatario"].offsetHeight;
        this.cont_visible = true;
        this.contenido_nuevoDesnatario = contenido_style(
          this.heigthScreen,
          this.header,
          this.footer,
          this.plataforma == "web" ? 63 : 0
        );
        this.blur_sup_nuevoDesnatario = blur_sup_style(this.header);
        this.blur_inf_nuevoDesnatario = blur_inf_style(this.footer);
      }, 100);

      setTimeout(() => {
        if (
          this.$refs["contenido_nuevoDesnatario"].scrollHeight >
          this.$refs["contenido_nuevoDesnatario"].clientHeight
        ) {
          this.scroll_to_bottom = false;
        }
      }, 100);
    });
  },
  ionViewWillLeave() {
    this.cont_visible = false;
    this.enVista = false;
    this.updateListaElementosDom([]);
    this.updateInfoNodos([]);
    this.updateRemitenteDestinatarios({});
  },
};
</script>

<style>
.nuevoDesnatarioTitle {
  margin-top: 1rem;

  color: var(--customA-801B1C);
  text-align: center;
}

.nuevoDesnatarioBody {
  min-height: 18rem;
  height: 100%;
  min-height: 18rem;
}

.nuevoDesnatarioInputCont {
  margin-top: 0.5rem;
}

.nuevoDesnatarioBtn {
  margin-top: 3rem;
  padding-left: 1.5625rem;
  padding-right: 1.5625rem;
}

.nuevoDesnatarioSubTitle {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 0.1rem;
  padding-top: 0.5rem;
}
.nuevoDesnatarioSubTitle div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.nuevoDesnatario_subT {
  font-size: 0.6rem;
  font-weight: 400;
  line-height: 1rem;
  color: var(--customA-801B1C);
  display: flex;
  justify-content: center;
}

.nuevoDesnatario_subT_destacado {
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1rem;
  color: var(--customA-801B1C);
  display: flex;
  justify-content: center;
}

.nuevoDesnatarioTitle_sub {
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1rem;
  color: var(--customA-801B1C);
  padding-top: 0.3rem;
}
</style>