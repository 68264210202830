<template>
  <div class="headerCerrarInfoCuenta">
    <div class="headerCerrarInfoCuentaIconsCont">
      <div class="headerCerrarInfoCuentaLogoInfo">
        <img :src="iconHeaderInfo" alt="" srcset="" @click="callInfo" />
      </div>
      <div class="headerCerrarInfoCuentaLogoInfo">
        <img :src="iconHeaderCuenta" alt="" srcset="" @click="callCuenta" />
      </div>
      
    </div>
    <!-- <div class="headerCerrarInfoCuentaLogoInfo">
      <img :src="iconHeaderInfo" alt="" srcset="" />
    </div> -->
    <div class="headerCerrarInfoCuentaIconCerrar">
      <img
        :src="
          colorIcon == 'rojoOscuro' ? iconoCerrarRojoOscuro : iconBack
        "
        alt=""
        srcset=""
        class="cerrar"
        @click="volver"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    colorIcon: { type: String },
  },
  data() {
    return {
      iconoCerrarRojoOscuro: require("../../assets/imgs/icono_cerrarRojoOscuro.svg"),
      iconBack: require("../../assets/imgs/iconBack.svg"),
      iconHeaderInfo: require("../../assets/imgs/iconHeaderInfo.svg"),
      iconHeaderCuenta: require("../../assets/imgs/iconHeaderCuenta.svg"),

    };
  },
  methods: {
    callInfo() {
      this.$emit("callInfo");
    },
    callCuenta() {
      this.$emit("callCuenta");
    },
    volver() {
      this.$emit("volver_");
    },
  },
};
</script>

<style scoped>
.headerCerrarInfoCuenta {
  display: flex;
  justify-content: space-between;
}
.headerCerrarInfoCuentaIconsCont {
  display: flex;
}
.headerCerrarInfoCuentaLogoInfo img {
  width: 2rem;
  cursor: pointer;
}
.headerCerrarInfoCuentaIconCerrar {
  width: 1rem;
    cursor: pointer;
}
</style>